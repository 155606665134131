






































































































































import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import {
  SfImage,
} from '@storefront-ui/vue';
import type { ItemInterface, ValuationInterface } from '@cellar-services/types';

export default defineComponent({
  name: 'SellingOptions',
  components: {
    Nullable,
    SfImage,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    valuation: {
      type: Object as PropType<ValuationInterface>,
      required: true,
    },
  },
  setup() {
  },
});
