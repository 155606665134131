





























































import {
  defineComponent,
  PropType,
  computed,
  ref,
} from '@nuxtjs/composition-api';
import Modal from '~/bbrTheme/components/organisms/Modal.vue';
import SellingOptions from '@cellar-services/components/molecules/ModalContent/SellingOptions.vue';
import { SELLING_OPTIONS_MODAL_CONTENT } from '@cellar-services/constants/sellingOptionsModalContent';
import type {
  ItemInterface,
  ValuationInterface,
  BidsForProductInterface,
  AcceptedBidInterface
} from '@cellar-services/types';

export default defineComponent({
  name: 'SellingOptionsModal',
  components: {
    Modal,
    SellingOptions,
    ListOnBBX: () => import('@cellar-services/components/molecules/ModalContent/ListOnBBX.vue'),
    ListOnBBXConfirmation: () => import('@cellar-services/components/molecules/ModalContent/ListOnBBXConfirmation.vue'),
    RemoveSingleListing: () => import('@cellar-services/components/molecules/ModalContent/RemoveSingleListing.vue'),
    AcceptBid: () => import('@cellar-services/components/molecules/ModalContent/AcceptBid.vue'),
    AcceptBidConfirmation: () =>
      import('@cellar-services/components/molecules/ModalContent/AcceptBidConfirmation.vue'),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isBackLabelShown: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    valuation: {
      type: Object as PropType<ValuationInterface>,
      required: true,
    },
    activeContent: {
      type: String,
      required: true
    },
    bidData: {
      type: Object as PropType<BidsForProductInterface>,
      required: true
    },
  },
  setup(props, { emit }) {
    const acceptedBid = ref<AcceptedBidInterface>();

    const handleBackFromListOnBBX = () => {
      return props.isBackLabelShown ?
        emit('update-active-content', SELLING_OPTIONS_MODAL_CONTENT.SELLING_OPTIONS) :
        emit('close');
    };

    const shouldShowBackLabel = computed(() => {
      return props.isBackLabelShown && (
        props.activeContent === SELLING_OPTIONS_MODAL_CONTENT.LIST_ON_BBX ||
        props.activeContent === SELLING_OPTIONS_MODAL_CONTENT.REMOVE_SINGLE_LISTING ||
        props.activeContent === SELLING_OPTIONS_MODAL_CONTENT.ACCEPT_HIGHEST_BID
      );
    })

    const sortedBids = computed(() => {
      return [...props.bidData.current_bids]
        .sort((a, b) => b.proposed_price - a.proposed_price);
    });

    const highestBid = computed(() => sortedBids.value[0]);

    const handleSuccessAcceptBid = (bid: AcceptedBidInterface) => {
      emit('update-active-content', SELLING_OPTIONS_MODAL_CONTENT.ACCEPT_HIGHEST_BID_CONFIRMATION);
      acceptedBid.value = bid;
    };

    const handleCloseModal = () => {
      emit('update-active-content', SELLING_OPTIONS_MODAL_CONTENT.SELLING_OPTIONS);
      emit('close');
    };

    return {
      SELLING_OPTIONS_MODAL_CONTENT,
      shouldShowBackLabel,
      handleBackFromListOnBBX,
      highestBid,
      handleSuccessAcceptBid,
      handleCloseModal,
      acceptedBid,
    }
  },
});
